import CONST from '@/utils/Constants';
import axios from 'axios/index';

export class ApiHelper {
    static getById(url, id) {
        return axios.get(ApiHelper.getUrlPrefix() + url + '/' + id);
    }

    static get(url, options = {params: null, headerType: null, server: null}) {
        return axios.get(ApiHelper.getUrlPrefix(options.server) + url, {
            headers: ApiHelper.getHeadersByOptions(options),
            params: options.params
        });
    }

    static getJSONFile(url, options = {params: null}) {
        return axios.get('/data/' + url, {
            headers: ApiHelper.getHeadersByOptions(options),
            params: options.params
        });
    }

    static post(url, data, options = {params: null, headersType: null, server: null}) {
      return axios.post(ApiHelper.getUrlPrefix(options.server) + url, data, {headers: ApiHelper.getHeadersByOptions(options)});
    }

    static put(url, data, options = {params: null, headersType: null}) {
        return axios.put(ApiHelper.getUrlPrefix() + url, data, {headers: ApiHelper.getHeadersByOptions(options)});
    }

    static delete(url, data) {
      return axios.delete(ApiHelper.getUrlPrefix() + url, {headers: ApiHelper.getHeaders()}, data);
  }

    static getUrlPrefix(server = null) {
      let prefix = CONST.ENV.VARS.VUE_APP_API_URL;

      if(server === 'blockrocker_cms') {
        prefix = 'https://continium.blockrocker.io/api';
      }

      return prefix;
    }

    static getHeadersByOptions(options = {headersType: null}) {
      let headers;

      if(options.headersType === 'basic') {
        headers = ApiHelper.getBasicHeaders();
      }else if(options.headersType === 'multipart') {
        headers = ApiHelper.getMultipartHeaders();
      }else if(options.headersType == 'twilio') {
        headers = ApiHelper.getTwilioHeaders();
      }else{
        headers = ApiHelper.getHeaders();
      }
      return headers;
    }

    static getBasicHeaders(contentType) {
        let headers = {};
        headers[CONST.KEY.REQUEST.ACCEPT] = CONST.VALUE.REQUEST.ACCEPT_JSON;
        headers[CONST.KEY.REQUEST.CONTENT_TYPE] = contentType;

        return headers;
    }

    static getMultipartHeaders() {
      let headers = ApiHelper.getBasicHeaders(CONST.VALUE.REQUEST.ACCEPT_MULTIPART);

      /*
      headers[CONST.KEY.REQUEST.AUTHORIZATION] =
          CONST.KEY.REQUEST.BEARER +
          localStorage.getItem(CONST.KEY.AUTH.ACCESS_TOKEN);
      */

      return headers;
    }

    static getHeaders() {
        let headers = ApiHelper.getBasicHeaders(CONST.VALUE.REQUEST.ACCEPT_JSON);

        /*
        headers[CONST.KEY.REQUEST.AUTHORIZATION] =
            CONST.KEY.REQUEST.BEARER +
            localStorage.getItem(CONST.KEY.AUTH.ACCESS_TOKEN);
        */

        return headers;
    }

    static parseUrlWithParameters(url, params) {
        if (params)
            for (let i = 0; i < params.length; i++)
                for (let key in params[i])
                    url = url.replace(':' + key, params[i][key]);

        return url;
    }
}

export default ApiHelper;
