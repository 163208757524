import CONST from "@/utils/Constants";
import ObjectHelper from "@/helpers/ObjectHelper";
import Notification from "@/utils/Notification";

export const ErrorHelper = {

    defaultDuration: 5000,

    catch: function (error, _this) {
      let $this = this;

      if (error.response) {
        error.response.data.errors.forEach(function(error) {

          if(error.type == 'validation') {
            _this.$refs[error.field].error = {message: error.title};
          }else{
            switch (error.code) {
              default:
                Notification.add(error.title, Notification.TYPE.ERROR, $this.defaultDuration);
              break;
              case CONST.API.EXCEPTION.AUTH_EXPIRED:
                Notification.add(_this.$t("notifications.error.auth-expired"), Notification.TYPE.ERROR, $this.defaultDuration);
                _this.$router.push(CONST.API.ROUTE.SESSION.LOGOUT, () => {
                });
              break;
              case CONST.API.EXCEPTION.INVALID_LOGIN:
                Notification.add(_this.$t("notifications.error.invalid-login"), Notification.TYPE.ERROR, $this.defaultDuration);
              break;
              case CONST.API.EXCEPTION.VALIDATION_ERROR:
                // Exception for regular validation handling
                // This is done on component level
              break;
            }
          }
        });
      }
    },

    reset(_this) {
      ObjectHelper.objectToArray(_this.$refs).forEach(function(ref) {
        ref.error = null;
      });
    }
};

export default ErrorHelper;
